import "./sideEffectModules/jQuery";
import "./sideEffectModules/bootstrap";

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip({ container: "body" });

    var userFormGreyout = function () {
        var rolle = $(this).val();
        var $rolleTeilnehmer = $(".js-rolle-teilnehmer");
        $rolleTeilnehmer.prop("disabled", rolle != "ROLE_TEILNEHMER");
    };
    var $jsRolleDropdown = $(".js-rolle-dropdown");
    $jsRolleDropdown.change(userFormGreyout);
    $jsRolleDropdown.trigger("change");

    var $modal = $("#modal");
    var registerLoadAjaxModal = function () {
        $(this).click(function (e) {
            e.preventDefault();
            var href = $(this).attr("href");
            var date = $("#fortbildung_startdatum").val();
            $.ajax({ url: href, data: { date: date }, type: "GET" }).done(function (response) {
                $modal.html(response);
                $modal.modal("show");

                $modal.find("[data-load-ajax-modal]").each(registerLoadAjaxModal);
            });
        });
    };
    $("[data-load-ajax-modal]").each(registerLoadAjaxModal);

    $modal.on("hidden.bs.modal", function (e) {
        var modalDocument = $modal.find("div[role=document]");
        if (modalDocument.data("on-hidden")) {
            var id = modalDocument.data("fortbildung-id");
            $("#fortbildung_" + id + "_icon span.glyphicon.glyphicon-plus")
                .removeClass("glyphicon-plus")
                .addClass("glyphicon-paperclip");
        }
    });

    var getChangeData = function (e) {
        var href = e.attr("href");
        var id = e.attr("data-watch-change");
        $.ajax({ url: href, data: { data: e.val() }, type: "GET" }).done(function (response) {
            $(id).html(response);

            $(id).find("[data-load-ajax-modal]").each(registerLoadAjaxModal);
        });
    };

    var registerWatchChange = function () {
        getChangeData($(this));
        $(this).change(function (e) {
            e.preventDefault();
            getChangeData($(this));
        });
    };
    $("[data-watch-change]").each(registerWatchChange);

    $(document).on("submit", "form.beleg-form", function (e) {
        e.preventDefault();

        var url = $(this).attr("action");
        var file = $("#beleg_file")[0].files[0];

        var data = new FormData();
        data.append("file", file);

        $.ajax({ url: url, data: data, cache: false, contentType: false, processData: false, type: "POST" }).done(
            function (response) {
                $modal.html(response);
                $modal.modal("show");

                if ($(".has-error").length == 0) {
                    var belege = document.querySelectorAll('tr[id^="beleg_"]');
                    if (belege.length > 0) {
                        var splitId = url.split("/");
                        var id = splitId[3];

                        var html =
                            "<span class='glyphicon glyphicon-paperclip' data-toggle='tooltip' data-placement='top' title='Datei angehängt' aria-hidden='true'></span>";
                        $("#fortbildung_" + id + "_icon > a")[0].innerHTML = html;
                    }
                }

                $modal.find("[data-load-ajax-modal]").each(registerLoadAjaxModal);
            }
        );
    });

    $("#fortbildung_startdatum").change(function () {
        $("#fortbildung_enddatum").val($("#fortbildung_startdatum").val());
    });

    $("#event_startdatum").change(function () {
        $("#event_enddatum").val($("#event_startdatum").val());
    });

    $("#anlage_quartal").change(function () {
        $.ajax({ url: $(this).data("href"), data: { quartal: $(this).val() }, type: "GET" }).done(function (response) {
            $("#anlage_expireAt").val(response);
        });
    });

    $(".dropdown.kommentar-vorlage .dropdown-menu li a").click(function (event) {
        var template = $(event.target).data("template");
        var lines = template.split("\n"), n = lines.length;
        var text = "", startOfLine = true, addNewLine = false;
        for (var i = 0; i < n; i++) {
            var line = lines[i].trim();
            addNewLine = line.endsWith("\\n");
            if (addNewLine) {
                line = line.substring(0, line.length - 2);
            }
            if (!startOfLine) {
                text += " ";
            }
            text += line;
            startOfLine = false;
            if (addNewLine) {
                text += "\r\n";
                startOfLine = true;
            }
        }
        var textArea = $("#fortbildung_kommentarDge"), oldText = textArea.val();
        oldText ? textArea.val(oldText + "\r\n" + text) : textArea.val(text);
    });

    $(".lll-datepicker").datepicker({
        dateFormat: "dd.mm.yy",
    });

    function fixedScrollbar() {
        var scrollbar = $('<div id="fixed-scrollbar"><div></div></div>').appendTo($(document.body));
        scrollbar.hide().css({
            overflowX: "auto",
            position: "fixed",
            width: "100%",
            bottom: 0,
        });
        var fakecontent = scrollbar.find("div");

        function top(e) {
            return e.offset().top;
        }

        function bottom(e) {
            return e.offset().top + e.height();
        }

        var active = $([]);
        function find_active() {
            scrollbar.show();
            var active = $([]);
            $(".fixed-scrollbar").each(function () {
                if (top($(this)) < top(scrollbar) && bottom($(this)) > bottom(scrollbar)) {
                    fakecontent.width($(this).get(0).scrollWidth);
                    fakecontent.height(1);
                    active = $(this);
                }
            });
            fit(active);
            return active;
        }

        function fit(active) {
            if (!active.length) return scrollbar.hide();
            scrollbar.css({ left: active.offset().left, width: active.width() });
            fakecontent.width($(this).get(0).scrollWidth);
            fakecontent.height(1);
            lastScroll = undefined;
        }

        function onscroll() {
            var oldactive = active;
            active = find_active();
            if (oldactive.not(active).length) {
                oldactive.unbind("scroll", update);
            }
            if (active.not(oldactive).length) {
                active.scroll(update);
            }
            update();
        }

        var lastScroll;
        function scroll() {
            if (!active.length) return;
            if (scrollbar.scrollLeft() === lastScroll) return;
            lastScroll = scrollbar.scrollLeft();
            active.scrollLeft(lastScroll);
        }

        function update() {
            if (!active.length) return;
            if (active.scrollLeft() === lastScroll) return;
            lastScroll = active.scrollLeft();
            scrollbar.scrollLeft(lastScroll);
        }

        scrollbar.scroll(scroll);

        onscroll();
        $(window).scroll(onscroll);
        $(window).resize(onscroll);
    }

    fixedScrollbar();
});
